import React, { useState } from 'react';
import faqItems from '../../data/faqdata/FaqsData';
import HeroSection from '../layout/heroSection/HeroSection';

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <>
      <HeroSection img="https://royal-education-image.vercel.app/learningResources.png" title="FAQs" description="Frequently Asked Questions (FAQ) about Homeschooling." />
      <section className="bg-white dark:bg-gray-900">
        <div className="container relative px-6 mx-auto md:max-w-[1240px] py-24">
          <h1 className="text-2xl font-semibold text-gray-800 lg:text-3xl dark:text-white">Frequently asked questions</h1>
          <div className="mt-8 space-y-8 lg:mt-12">
            {faqItems.map((item, index) => (
              <div key={index} className="p-8 bg-gray-100 rounded-lg dark:bg-gray-800">
                <button className="flex items-center justify-between w-full" onClick={() => toggleFAQ(index)}>
                  <h1 className="font-semibold text-gray-700 dark:text-white">{item.question}</h1>
                  <span className="text-gray-400 bg-gray-200 rounded-full">
                    {activeIndex === index ? (
                      <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M18 12H6" />
                      </svg>
                    ) : (
                      <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                      </svg>
                    )}
                  </span>
                </button>
                {activeIndex === index && (
                  <p className="mt-6 text-sm text-gray-500 dark:text-gray-300 text-left">
                    {item.answer}
                  </p>
                )}
              </div>
            ))}
          </div>
        </div>
      </section>
    </>

  );
}

export default FAQ;


