import React from 'react'
import { Divider } from 'antd'
import HeroSection from '../layout/heroSection/HeroSection'

const Testimonial = () => {
    return (
        <>
    
            <HeroSection img="https://img.freepik.com/free-photo/charming-assertive-female-shop-assistant-pointing-upper-left-corner-promote-cool-product-smili_1258-135655.jpg?t=st=1723014825~exp=1723018425~hmac=869635a8e525bbc3e1c1a8a37b8290ae586c1dd9b8b659d0724d1bee99d44dfc&w=1800" title="Testimonials" description="Discover what our community is saying about their transformative experiences with us." />
            <section className='my-10'>
                <div className='container mx-auto px-6 py-3 max-w-[1240px] grid grid-cols-1 md:grid-cols-4 md:gap-10 gap-5 '>
                    <div className=''>
                        <div class="max-w-2xl px-2 py-4 bg-white rounded-lg shadow-md dark:bg-gray-800">
                            <div class="mt-2">
                                <p class="mt-2 text-gray-600 dark:text-gray-300 font-Inter">“Royal Education's analytics have empowered us to target areas for improvement and celebrate achievements, significantly boosting our child's academic performance.”</p>
                            </div>
                            <div class="flex items-center justify-center mt-4">
                                <div class="flex items-center">
                                    {/* <img class=" object-cover w-10 h-10 mx-4 rounded-full" src={logo} alt="avatar" /> */}
                                    <a href='#d' class="font-semibold text-gray-700 cursor-pointer dark:text-gray-200 font-Inter" tabindex="0" >— Mark A., Father of a 5th Grader</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='md:col-span-2 md:row-span-2'>
                        <div class="max-w-2xl px-8 py-4 bg-white rounded-lg shadow-md h-full">
                            <div class="mt-2">
                                <p class="mt-2 text-gray-600 dark:text-gray-300 md:text-3xl md:font-semibold font-Inter">“Our experience with Royal Education has been nothing short of transformative. The tailored PreK-12th programs have bridged learning gaps and built my child's confidence in subjects they once struggled with. The flexibility to learn at home while receiving a high-quality education has been invaluable for our family.”</p>
                            </div>
                            <Divider className='hidden md:block' />
                            <div class="flex items-center justify-center mt-4">
                                <div class="flex items-center">
                                    {/* <img class=" object-cover w-10 h-10 mx-4 rounded-full" src={logo} alt="avatar" /> */}
                                    <a href='#d' class="font-semibold text-gray-700 cursor-pointer dark:text-gray-200 font-Inter" tabindex="0" >— Sarah J., Mother of a 7th Grader</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className=''>
                        <div class="max-w-2xl px-2 py-4 bg-white rounded-lg shadow-md dark:bg-gray-800">
                            <div class="mt-2">
                                <p class="mt-2 text-gray-600 dark:text-gray-300 font-Inter">“The seamless tracking of my child's progress and readiness for standardized testing has been transformative, with detailed, actionable reports from Royal Education guiding informed educational decisions effortlessly.”</p>
                            </div>
                            <div class="flex items-center justify-center mt-4">
                                <div class="flex items-center">
                                    {/* <img class=" object-cover w-10 h-10 mx-4 rounded-full" src={logo} alt="avatar" /> */}
                                    <a href='#d' class="font-semibold text-gray-700 cursor-pointer dark:text-gray-200 font-Inter" tabindex="0" >— Emily K., Mother of a 9th Grader</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className=''>
                        <div class="max-w-2xl px-2 py-4 bg-white rounded-lg shadow-md dark:bg-gray-800">
                            <div class="mt-2">
                                <p class="mt-2 text-gray-600 dark:text-gray-300 font-Inter">“Royal Education's evolving curriculum from kindergarten through middle school keeps my children engaged and challenged, with well-structured lessons aligned to the NCERT syllabus, ensuring they meet national standards effectively.”</p>
                            </div>
                            <div class="flex items-center justify-center mt-4">
                                <div class="flex items-center">
                                    {/* <img class=" object-cover w-10 h-10 mx-4 rounded-full" src={logo} alt="avatar" /> */}
                                    <a href='#d' class="font-semibold text-gray-700 cursor-pointer dark:text-gray-200 font-Inter" tabindex="0" >— Priya S., Mother of 3 Children (Kindergarten, 3rd Grade, 8th Grade)</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className=''>
                        <div class="max-w-2xl px-2 py-4 bg-white rounded-lg shadow-md dark:bg-gray-800">
                            <div class="mt-2">
                                <p class="mt-2 text-gray-600 dark:text-gray-300 font-Inter">“Royal Education's real-time analytics offer immediate insights into my child's performance, facilitating targeted attention to areas needing improvement and efficient planning for their education, supported by time-saving reports and practical details.”</p>
                            </div>
                            <div class="flex items-center justify-center mt-4">
                                <div class="flex items-center">
                                    {/* <img class=" object-cover w-10 h-10 mx-4 rounded-full" src={logo} alt="avatar" /> */}
                                    <a href='#d' class="font-semibold text-gray-700 cursor-pointer dark:text-gray-200 font-Inter" tabindex="0" >— James L., Father of a 6th Grader</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className=''>
                        <div class="max-w-2xl px-2 py-4 bg-white rounded-lg shadow-md dark:bg-gray-800">
                            <div class="mt-2">
                                <p class="mt-2 text-gray-600 dark:text-gray-300 font-Inter">“Royal Education has streamlined our homeschooling journey with tailored learning experiences and comprehensive NCERT syllabus coverage, fostering my children's thriving academic progress and boosting my confidence in our educational choices.”</p>
                            </div>
                            <div class="flex items-center justify-center mt-4">
                                <div class="flex items-center">
                                    {/* <img class=" object-cover w-10 h-10 mx-4 rounded-full " src={logo} alt="avatar" /> */}
                                    <a href='#d' class="font-semibold text-gray-700 cursor-pointer dark:text-gray-200 font-Inter" tabindex="0" >— Ananya R., Homeschooling Mother of 2</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className=''>
                        <div class="max-w-2xl px-2 py-4 bg-white rounded-lg shadow-md dark:bg-gray-800">
                            <div class="mt-2">
                                <p class="mt-2 text-gray-600 dark:text-gray-300 font-Inter">“Royal Education has empowered me to take charge of my child's education with its user-friendly platform and award-winning programs, providing a strong foundation for learning and the flexibility I value in our educational journey.”</p>
                            </div>
                            <div class="flex items-center justify-center mt-4">
                                <div class="flex items-center">
                                    {/* <img class=" object-cover w-10 h-10 mx-4 rounded-full " src={logo} alt="avatar" /> */}
                                    <a href='#d' class="font-semibold text-gray-700 cursor-pointer dark:text-gray-200 font-Inter" tabindex="0" >— Maria T., Mother of a 4th Grader</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className=''>
                        <div class="max-w-2xl px-2 py-4 bg-white rounded-lg shadow-md dark:bg-gray-800">
                            <div class="mt-2">
                                <p class="mt-2 text-gray-600 dark:text-gray-300 font-Inter">“The insights and reports from Royal Education have enabled us to implement more efficient teaching strategies, addressing my child's learning needs promptly and effectively, resulting in significant improvements in their academic skills and confidence.”</p>
                            </div>
                            <div class="flex items-center justify-center mt-4">
                                <div class="flex items-center">
                                    {/* <img class=" object-cover w-10 h-10 mx-4 rounded-full " src={logo} alt="avatar" /> */}
                                    <a href='#d' class="font-semibold text-gray-700 cursor-pointer dark:text-gray-200 font-Inter" tabindex="0" >— Ravi M., Father of a 10th Grader</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Testimonial
