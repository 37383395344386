import React, { useEffect, useState } from 'react';
import SpinnerAntd from '../../../components/spinner/SpinnerAntd';
import SidebarGrade from '../SidebarGrade';
import { useParams } from 'react-router-dom';
import BASE_URL from '../../../config/config';
import axios from 'axios';
import HeroSection from '../../layout/heroSection/HeroSection';

const Topic = () => {
  const { id, topicName } = useParams();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [islogin, setIsLogin] = useState(false);

  useEffect(() => {
    if (localStorage.getItem('login')) {
      setIsLogin(true);
    }
  }, []);

  const handleClick = () => {
    window.location.href = '/sign-in';
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/video`);
        setData(response.data);
        setLoading(false);
      } catch (err) {
        console.error('Failed to fetch topics:', err);
      }
    };

    fetchData();
  }, []);

  const filteredTopicVideos = data.filter(video =>
    video.topic_id === parseInt(id, 10)
  );

  return (
    <>
      <HeroSection img="https://royal-education-image.vercel.app/learningResources.png" title={`${topicName}`} description="Fueling Curiosity, Enhancing Knowledge." />
      {
        islogin ? (
          <div className="container mx-auto max-w-[1240px] py-16 md:py-28">
            <div data-aos="fade-up" data-aos-delay="200">
              <h2 className="font-semibold text-3xl text-black font-Inter uppercase dark:text-white">{topicName}</h2>
            </div>
            <div className="flex">
              <SidebarGrade />
              <div className="px-4 flex-grow">
                {loading ? (
                  <SpinnerAntd />
                ) : (
                  <>
                    <div className="container mx-auto max-w-[1240px] grid grid-cols-1 md:grid-cols-3 gap-10 justify-items-center my-10">
                      {filteredTopicVideos.length > 0 ? (
                        filteredTopicVideos.map((video, index) => (
                          <div key={index} className="max-w-sm rounded overflow-hidden shadow-lg dark:bg-white">
                            <div className="w-full h-56">
                              <iframe
                                className="w-full h-full"
                                src={video.url}
                                title={`YouTube video player`}
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                              ></iframe>
                            </div>
                            <div className="px-6 py-4">
                              <div className="font-bold text-xl mb-2">{video.title}</div>
                              <p className="text-gray-700 text-base">{video.description}</p>
                            </div>
                          </div>
                        ))
                      ) : (
                        <p className="text-gray-500 dark:text-gray-300 text-center">(Video not found)</p>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="container mx-auto max-w-[1240px] py-16 md:py-28">
            <h2 className="font-semibold text-3xl text-black font-Inter dark:text-white">Please login to access learning resources</h2>
            <p className='py-1 mt-5 px-3 rounded-md inline-block bg-blue cursor-pointer text-white hover:bg-white hover:text-blue hover:border' onClick={handleClick}>Go to Login?</p>
          </div>
        )
      }
    </>
  );
}

export default Topic;
