const faqItems = [
    {
      question: 'What is homeschooling?',
      answer: 'Homeschooling is an educational method where children are taught at home by their parents or guardians instead of attending a traditional public or private school.'
    },
    {
      question: 'Is homeschooling legal?',
      answer: 'Yes, homeschooling is legal in many countries, including the United States. However, specific regulations and requirements vary by state and country. Its important to research local laws and comply with any registration, assessment, or curriculum standards.'
    },
    {
      question: 'What are the benefits of homeschooling?',
      answer: 'Homeschooling offers personalized education, flexible scheduling, one-on-one instruction, and the ability to tailor the curriculum to a childs interests and learning style. It can also create a closer family bond and provide a safe and focused learning environment.'
    },
    {
      question: 'How do I start homeschooling?',
      answer: 'To start homeschooling, research your local laws and requirements, choose a curriculum, set a schedule, and create a conducive learning environment at home. Joining local or online homeschooling communities can also provide support and resources.'
    },
    {
      question: 'Do I need to be a certified teacher to homeschool my child?',
      answer: 'No, in most places, you do not need to be a certified teacher to homeschool your child. However, you should be committed to learning about educational methods and resources to effectively teach your child.'
    },
    {
      question: 'How do I choose a curriculum?',
      answer: 'Choosing a curriculum depends on your childs learning style, interests, and educational goals. There are many resources available, including pre-packaged curricula, online programs, and the ability to create your own curriculum from various educational materials.'
    },
    {
      question: 'How can I ensure my child is socialized?',
      answer: 'Homeschooling families can ensure socialization by participating in co-ops, extracurricular activities, sports teams, community events, and other group activities. Many homeschooling communities offer regular meet-ups, field trips, and social events.'
    },
    {
      question: 'Can homeschooling accommodate children with special needs?',
      answer: 'Yes, homeschooling can be tailored to meet the specific needs of children with special needs, providing individualized attention and customized learning plans. Many resources and support groups are available for parents homeschooling children with special needs.'
    },
    {
      question: 'How is my child’s progress assessed in homeschooling?',
      answer: 'Assessment methods vary and can include standardized tests, portfolio reviews, written evaluations, and regular feedback from parents. Some states have specific assessment requirements, so it’s important to be aware of local regulations.'
    },
    {
      question: 'What if I want to stop homeschooling and enroll my child in a traditional school?',
      answer: 'If you decide to stop homeschooling, contact your local school district to learn about the enrollment process and any required assessments or documentation. Many schools will work with families to transition children smoothly into a traditional school setting.'
    },
    {
      question: 'How do homeschooled students perform academically compared to traditionally schooled students?',
      answer: 'Research shows that homeschooled students often perform as well or better academically compared to their peers in traditional schools. However, outcomes can vary depending on the quality of the homeschooling program and the individual students needs.'
    },
    {
      question: 'Can homeschooled students go to college?',
      answer: 'Yes, homeschooled students can and do go to college. Many colleges and universities welcome homeschooled applicants and may have specific admissions guidelines for them. Homeschooled students often need to provide detailed transcripts, standardized test scores, and evidence of their academic achievements.'
    },
  ];

  export default faqItems;