import { Carousel } from 'antd'
import React from 'react'
import heroSectionVideo from '../../assets/video/heroSectionVideo.mp4'

const HeroSection = () => {
    return (
        <div className="relative w-full min-h-[500px] md:h-[80vh] bg-cover bg-center bg-black">
            <video
                autoPlay
                muted
                loop
                className="absolute top-0 left-0 w-full h-full object-cover opacity-40"
            >
                <source src={heroSectionVideo} type="video/mp4" />
            </video>
            <div className='absolute inset-0 flex justify-center items-center'>
                <div className="container mx-auto px-6 py-3 md:max-w-[1240px] grid grid-cols-1  justify-items-center">
                    <div className="max-w-[375px] md:max-w-[1240px]  rounded overflow-hidden my-4 py-36">
                        <Carousel dots={false} arrows autoplay={true} speed={500}  >
                            <div className="p-3 md:py-12">
                                <h2 className='font-Poppins-Bold lg:text-6xl md:text-4xl text-2xl  text-white leading-tight'>Empower Your Child's Learning</h2>
                                <p className='font-Poppins md:text-2xl mt-5 text-white leading-3'>Discover strengths and address weaknesses</p>
                                <p className='font-Poppins md:text-2xl mt-2 text-white'>with Royal Education Analytics.</p>

                            </div>
                            <div className="p-3 md:py-12">
                                <h2 className='font-Poppins-Bold lg:text-6xl md:text-4xl text-2xl text-white leading-tight'>Transform Data into Insights</h2>
                                <p className='font-Poppins md:text-2xl mt-5 text-white leading-3 mx-5'>Effortlessly track progress and readiness for standardized </p>
                                <p className='font-Poppins md:text-2xl mt-2 text-white'>testing with just a few clicks.</p>

                            </div>
                            <div className="p-3 md:py-12">
                                <h2 className='font-Poppins-Bold lg:text-6xl md:text-4xl text-2xl text-white leading-tight'>Flexible Home Education</h2>
                                <p className='font-Poppins md:text-2xl mt-5 text-white leading-3'>Bridge learning gaps and build confidence </p>
                                <p className='font-Poppins md:text-2xl mt-2 text-white'>with our tailored PreK-12th programs.</p>

                            </div>

                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeroSection
