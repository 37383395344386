const TermData = [
    {
      heading: '1. Use of Website and Services',
      subSections: [
        {
          subHeading: 'Eligibility',
          text: 'You must be at least 18 years old to use our services. If you are under 18, you may use our services only with the involvement of a parent or legal guardian.'
        },
        {
          subHeading: 'License to Use',
          text: 'Subject to these Terms, we grant you a limited, non-exclusive, non-transferable, and revocable license to access and use our Website and services for educational purposes only. You may not use our services for any illegal or unauthorized purpose.'
        },
        {
          subHeading: 'User Account',
          text: 'To access certain features of our Website and services, you may need to create a user account. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.'
        },
        {
          subHeading: 'Prohibited Activities',
          text: 'When using our Website and services, you agree not to:',
          listItems: [
            'Violate any applicable laws or regulations.',
            'Infringe upon the rights of others, including intellectual property rights.',
            'Interfere with or disrupt our Website or servers.',
            'Attempt to gain unauthorized access to our systems or networks.'
          ]
        }
      ]
    },
    {
      heading: '2. Educational Services',
      subSections: [
        {
          subHeading: 'Content',
          text: 'Our educational services include access to online learning materials, interactive exercises, and educational resources aimed at students and facilitated by parents or legal guardians.'
        },
        {
          subHeading: 'Modifications',
          text: 'We reserve the right to modify or discontinue any part of our Website or services at any time without prior notice. We are not liable to you or any third party for any modification, suspension, or discontinuation of our services.'
        }
      ]
    },
    {
      heading: '3. User Responsibilities',
      subSections: [
        {
          subHeading: 'Parental Responsibility',
          text: 'Parents or legal guardians are responsible for supervising childrens use of our Website and ensuring that they comply with these Terms'
        },
        {
          subHeading: 'Accuracy of Information',
          text: 'You agree to provide accurate, current, and complete information when using our Website and services. You are responsible for updating your information as necessary to maintain its accuracy.'
        }
      ]
    },
    {
      heading: '4. Privacy',
      text: 'Our collection and use of your personal information are governed by our Privacy Policy. By using our Website and services, you consent to the collection, use, and sharing of your information as described in the Privacy Policy.'
    },
    {
      heading: '5. Intellectual Property Rights',
      subSections: [
        {
          subHeading: 'Ownership',
          text: 'All content and materials available on our Website, including text, graphics, logos, icons, images, audio clips, and software, are the property of Royal Education or its licensors and are protected by copyright, trademark, and other intellectual property laws.'
        },
        {
          subHeading: 'License',
          text: 'Subject to these Terms, we grant you a limited, non-exclusive, non-transferable license to access and use the content and materials on our Website solely for your personal, non-commercial use related to educational purposes.'
        },
        {
          subHeading: 'Restrictions',
          text: 'You may not reproduce, modify, distribute, display, perform, or use any content from our Website except as expressly permitted under these Terms or with our prior written consent.'
        }
      ]
    },
    {
        heading: '6. Third-Party Links and Content',
        text:'Our Website may contain links to third-party websites or services that are not owned or controlled by Royal Education. We are not responsible for the content, privacy policies, or practices of any third-party websites. You acknowledge and agree that we are not liable for any loss or damage arising from your use of any third-party websites.'
    },
    {
        heading: '7. Limitation of Liability',
        text:'To the fullest extent permitted by law, Royal Education and its affiliates, directors, officers, employees, agents, and licensors shall not be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from:',
        listItem:[
            'Your access to or use of, or inability to access or use, our Website or services.',
            'Any conduct or content of any third party on our Website.',
            'Any unauthorized access to or use of our servers and/or any personal information stored therein.',
            'Any errors or omissions in any content or materials on our Website.',
            'Any interruption or cessation of transmission to or from our Website.'
        ]
    },
    {
        heading: '8. Indemnification',
        text:'You agree to indemnify and hold harmless Royal Education and its affiliates, directors, officers, employees, agents, and licensors from and against any claims, liabilities, damages, losses, and expenses, including reasonable legal fees and costs, arising out of or in any way connected with:',
        listItem:[
            'Your access to or use of our Website or services.',
            'Your violation of these Terms.',
            'Your violation of any rights of another party.',
        ]
    },
    {
        heading:'9. Governing Law and Dispute Resolution',
        text:'These Terms shall be governed by and construed in accordance with the laws of India, without regard to its conflict of law principles. Any dispute arising out of or relating to these Terms or your use of our Website shall be exclusively resolved by the courts located in Mehsana, Gujarat, India.'
    },
    {
        heading:'10. Changes to These Terms',
        text:'We reserve the right to update or modify these Terms at any time without prior notice. Your continued use of our Website after any such changes constitutes your acceptance of the new Terms.'
    },
    
  ];
 
  export default TermData;