import Aos from 'aos';
import './App.css';
import 'aos/dist/aos.css';
import 'react-toastify/dist/ReactToastify.css';
import PublicRoute from './route/PublicRoute';
import { useEffect } from 'react';

function App() {
  useEffect(() => {
    Aos.init();
  }, [])
  return (
    <>
    <PublicRoute />
    </>
  );
}

export default App;
