import React from 'react'
import HeroSection from '../layout/heroSection/HeroSection'

const PrivacyPolicy = () => {
    const Heading = ({ heading }) => {
        return (
            <h2 className='font-Inter font-semibold text-lg mb-3 dark:text-white'>{heading}</h2>
        )
    }
    const SubHeading = ({ subHeading }) => {
        return (
            <p className='text-base font-semibold font-Inter mb-3 dark:text-white'>{subHeading}</p>
        )
    }
    const Text = ({ text }) => {
        return (
            <p className='text-sm font-Inter mb-3 dark:text-white'>{text}</p>
        )
    }
    const ContactInfo = ({ contactinfo }) => {
        return (
            <p className='text-[12px] font-Inter dark:text-white'>{contactinfo}</p>
        )
    }

    return (
        <>
            <HeroSection img="https://royal-education-image.vercel.app/privacy-policy.png" title="Privacy & Policy" description="Protecting Your Information, Ensuring Transparency." />
            <div className="container relative px-6 mx-auto md:max-w-[1240px] py-10">
                <h2 className='text-2xl font-Inter font-semibold underline mb-7 dark:text-white'>Privacy & Policy </h2>
                <div className='text-left'>
                    <SubHeading subHeading={'Privacy Policy for Royal Education'} />
                    <Text text={'Effective Date: 20th June, 2024 '} />
                    <p className='text-sm font-Inter mb-3 dark:text-white'>Royal Education ("we", "us", "our") is committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website<a href="https://royalhomeschool.in" className='text-blue underline'> [https://royalhomeschool.in]</a> ("Website") and when you use our services. </p>

                    <Heading heading={'1. Introduction'} />
                    <Text text={'Royal Education provides online educational services aimed at enhancing the learning experience of students under the guidance of parents and legal guardians. We respect your privacy and are committed to protecting the personal information you provide to us.'} />

                    <Heading heading={'2. Information We Collect'} />
                    <SubHeading subHeading={'Information You Provide'} />
                    <ul className='list-disc ml-12 font-Inter text-sm mb-3 dark:text-white'>
                        <li><b>Registration and Account Information:</b> When you register on our Website or subscribe to our services, we collect personal information such as your name, email address, phone number, and billing information.</li>
                        <li><b>Student Information:</b>We collect information about students for educational purposes only, which may include their name, grade level, and subjects of study. </li>
                        <li><b>Communications:</b> When you contact us or participate in surveys or promotions, we may collect your contact details and any other information you choose to provide.</li>
                    </ul>
                    <SubHeading subHeading={'Information We Automatically Collect'} />
                    <ul className='list-disc ml-12 font-Inter text-sm mb-3 dark:text-white'>
                        <li><b>Usage Information:</b> We may collect information about your interactions with our Website, such as your IP address, browser type, referring/exit pages, and pages visited. </li>
                        <li><b>Cookies and Similar Technologies:</b>We use cookies and similar technologies to enhance your experience on our Website, analyze trends, administer the Website, track users’ movements around the Website, and gather demographic information about our user base as a whole. You can manage your cookie preferences through your browser settings.</li>
                    </ul>

                    <Heading heading={'3. Use of Information'} />
                    <Text text={'We use the information we collect for the following purposes:'} />
                    <ul className='list-disc ml-12 font-Inter text-sm mb-3 dark:text-white'>
                        <li>To provide and personalize our educational services. </li>
                        <li>To communicate with you, including sending service-related announcements, updates, and promotional materials that may be of interest to you. </li>
                        <li>To improve our Website, services, and customer support. </li>
                        <li>To comply with legal obligations, including responding to legal process or requests from governmental authorities.</li>
                    </ul>

                    <Heading heading={'4. Disclosure of Information'} />
                    <Text text={'We may disclose your personal information in the following circumstances:'} />
                    <ul className='list-disc ml-12 font-Inter text-sm mb-3 dark:text-white'>
                        <li><b>Service Providers:</b>We may share your information with third-party service providers who assist us in providing and improving our services, subject to contractual agreements and security measures. </li>
                        <li><b>Legal Compliance:</b>We may disclose information when required by law, legal process, or governmental request. </li>
                        <li><b>Business Transfers:</b> In the event of a merger, acquisition, reorganization, or sale of assets, your personal information may be transferred or disclosed as part of the transaction.</li>
                    </ul>


                    <Heading heading={'5. Data Security'} />
                    <Text text={'We implement appropriate technical and organizational measures to protect your personal information against unauthorized access, alteration, disclosure, or destruction. These measures include encryption, access controls, and regular security audits. Despite our efforts to protect your personal information, no security measures are completely secure, and we cannot guarantee the security of your information transmitted to our Website.'} />

                    <Heading heading={'6. Your Rights and Choices'} />
                    <ul className='list-disc ml-12 font-Inter text-sm mb-3 dark:text-white'>
                        <li><b>Access and Correction:</b>You have the right to access and update your personal information. You can do this by logging into your account or contacting us directly.</li>
                        <li><b>Data Retention:</b>We retain your information only as long as necessary for the purposes outlined in this Privacy Policy or as required by law. </li>
                    </ul>

                    <Heading heading={"7. Children's Privacy Policy"} />
                    <Text text={'Royal Education is committed to protecting the privacy of children. Our services are intended for use by parents and legal guardians. We do not knowingly collect personal information from children under the age of 16 without parental consent. If we learn that we have inadvertently collected personal information from a child without parental consent, we will take steps to delete the information as soon as possible.'} />

                    <Heading heading={'8. GDPR Compliance'} />
                    <Text text={'If you are located in the European Union, you have rights under the General Data Protection Regulation (GDPR), including the right to access, rectify, or erase your personal data. You can exercise these rights by contacting us using the information provided below.'} />

                    <Heading heading={'9. Third-Party Links'} />
                    <Text text={'Our Website may contain links to third-party websites. We are not responsible for the privacy practices or content of these third-party sites. We encourage you to read the privacy policies of these websites before providing any personal information.'} />

                    <Heading heading={'10. Changes to This Privacy Policy'} />
                    <Text text={'We may update this Privacy Policy from time to time to reflect changes in our practices and legal requirements. The policy can change at any time without prior notice. Your continued use of our Website after any such changes constitutes your acceptance of the new Privacy Policy.'} />

                    <Heading heading={'11. Contact Us'} />
                    <Text text={'If you have any questions, concerns, or requests regarding this Privacy Policy or our data practices, please contact us at:'} />
                    <ContactInfo contactinfo={'Royal Education'} />
                    <ContactInfo contactinfo={'Vithoda, Ta.: Kheralu,'} />
                    <ContactInfo contactinfo={'Dist.: Mehsana - 384325'} />
                    <ContactInfo contactinfo={'Email: support@royalhomeschool.in'} />

                </div>
            </div>
        </>
    )
}

export default PrivacyPolicy
