import React, { useEffect, useState } from 'react'
import SpinnerAntd from '../../../components/spinner/SpinnerAntd';
import { RightOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import BASE_URL from '../../../config/config';
import axios from 'axios';

const LearningVideo = () => {

  const [grades, setGrades] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchGrades = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/class`);
        setGrades(response.data);
        setLoading(false)
      } catch (err) {
        console.error('Failed to fetch grades:', err);
      }
    };

    fetchGrades();
  }, []);


  return (
    <div className="flex ">
      <div className="px-4 flex-grow">
        {loading ? (
          <SpinnerAntd />
        ) : (
          <>
            <ul className="grid grid-cols-1 gap-4 mt-8 xl:mt-12 xl:gap-4 md:grid-cols-3 text-left">
              {
                grades.length > 0 ? (
                  grades.map((grade, index) => (
                    <li key={index} className="p-6 border rounded-xl border-r-gray-200 dark:border-gray-700">
                      <div className=" md:mt-0">
                        <h1 className="text-xl font-medium text-gray-700 capitalize dark:text-white">
                          <Link to={`/${grade.name}/subject/${grade.id}`} className="text-green-500 dark:text-darkwhite font-Inter block">
                            {grade.name}
                          </Link>
                        </h1>
                        <p className="my-2 text-gray-500 dark:text-gray-300">
                          {grade.description}
                        </p>
                        <hr />
                        <Link to={`/${grade.name}/subject/${grade.id}`} className="text-green-500 dark:text-darkwhite font-Inter mt-3 block">
                          View More <RightOutlined />
                        </Link>

                      </div>
                    </li>
                  )
                  )
                ) : (
                  <p>No grades found</p>
                )
              }

            </ul>
          </>

        )}
      </div>
    </div>
  );
}

export default LearningVideo
