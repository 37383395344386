import { HomeOutlined, PhoneOutlined, UserOutlined } from '@ant-design/icons';
import {MailOutline } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import SpinnerAntd from '../spinner/SpinnerAntd';
import profiledata from '../../data/api/apiData';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

const Profile = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProfile = async () => {
      setLoading(true);
      const profile = await profiledata();
      setData(profile);
      setLoading(false);
    };

    fetchProfile();
  }, []);

  if (loading) {
    return <SpinnerAntd />;
  }

  return (
    <>
      <div className="relative w-full h-44 md:h-[40vh] bg-[url('https://royal-education-image.vercel.app/curriculum.png')] bg-cover bg-center">
        <div className="absolute inset-0 bg-black opacity-60"></div>
        <div className="absolute inset-0 flex justify-center items-center">
          <div className="justify-items-center">
            <h2 className="text-white font-bold text-2xl md:text-5xl font-Inter">
              Profile
            </h2>
          </div>
        </div>
      </div>

      <section className="bg-white dark:bg-gray-900 py-24">
        <div className="container items-center justify-center px-6 mx-auto md:max-w-[1240px]">
          <div className="bg-white overflow-hidden shadow rounded-lg border pb-5">
            <div className="px-4 py-5 sm:px-6">
              <h3 className="text-lg leading-6 font-medium text-gray-900">Profile Details</h3>
              <div className="h-[150px] flex justify-center md:justify-start mt-2  items-center">
                <img src={data?.imageUrl} height={150} width={150} alt="profile img" className="rounded-full border-2 border-gray-300" />
              </div>
            </div>
            <div className="border-t border-gray-200 px-4 py-5 sm:p-0 text-left">
              <dl className="sm:divide-y sm:divide-gray-200">
                <div className="py-3 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Full Name</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    <UserOutlined className='mr-2' />
                    {data?.profile?.name}
                  </dd>
                </div>
                <div className="py-3 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Email Address</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex items-center">
                    {/* <EmailOutlined className="mr-2" /> */}
                    <MailOutline className='mr-2' fontSize='10'/>
                    {data?.profile?.email}
                  </dd>
                </div>
                <div className="py-3 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Phone Number</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex items-center">
                    <PhoneOutlined className="mr-2" />
                    {data?.profile?.phoneNumber || 'NA'}
                  </dd>
                </div>
                <div className="py-3 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Address</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    <HomeOutlined className='mr-2'/>
                    {data?.profile?.address || 'NA'}
                  </dd>
                </div>
              </dl>
              <div className='md:ml-5 border-t'>
              <Button sx={{marginTop:'10px'}} variant="contained"><Link to={'/edit-profile'}>Edit</Link></Button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Profile;
