import React, { useState, useEffect, useRef } from 'react';
import { DownOutlined, LogoutOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons'
import SpinnerAntd from '../spinner/SpinnerAntd';
import { ToastContainer, toast } from 'react-toastify';
import { Link } from 'react-router-dom';

const ProfileDropDown = ({ data }) => {
    
    const [isOpen, setIsOpen] = useState(false);
    // const [profile, setProfile] = useState(data);
    const [loading, setLoading] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleLogout = () => {
        setLoading(true);
        console.log('logout');
        localStorage.clear();
        toast('Logout successfully');
        setTimeout(() => {
            setLoading(false);
            window.location.href='/'
        }, 2000);
    };

    return (
        <div ref={dropdownRef} className="relative inline-block font-Inter">
            <button 
                onClick={() => setIsOpen(!isOpen)} 
                className="flex items-center justify-between gap-2 px-4 py-1 border border-opacity-20 bg-gray-100 border-gray-600 rounded-md focus:outline-none"
            >
                {/* <div className="w-8 h-8 rounded-full bg-gray-600 flex items-center justify-center"> */}
                    <span className="">{data?.name}</span>
                {/* </div> */}
                <DownOutlined style={{color:'black' , fontSize:15}} />
            </button>

            {isOpen && (
                <div className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded-md shadow-lg z-20 ">
                    <button className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                        <UserOutlined/>
                        <span className="ml-2">{data?.name}</span>
                    </button>
                    <div className="border-t border-gray-100"></div>
                    <button className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                        
                        <SettingOutlined />
                        <span className="ml-2">
                            <Link to={'/profile-setting'}>
                            View Profile
                            </Link>
                            </span>
                    </button>
                    <div className="border-t border-gray-100"></div>
                    <button className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" onClick={handleLogout}>
                    <LogoutOutlined />
                        <span className='ml-2'>Logout</span>
                        {loading && <span className="ml-2 loader"></span>} 
                    </button>
                </div>
            )}
            <ToastContainer />

            {loading && (
                <SpinnerAntd />
            )}
        </div>
    );
};

export default ProfileDropDown;
