import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import axios from 'axios'
import BASE_URL from '../../../config/config'
import SpinnerAntd from '../../../components/spinner/SpinnerAntd'
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import registerImg from '../../../assets/images/register.jpg'

const SignUp = () => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        password: '',
        cpassword: ''
    })

    const [errorData, setErrorData] = useState({
        nameError: '',
        emailError: '',
        passwordError: '',
        cpasswordError: ''
    })

    useEffect(() => {
        const login = localStorage.getItem('login')
        if (login === 'true') {
            window.location.href = '/'
        }
    }, [])

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        // Reset error data
        setErrorData({
            nameError: '',
            emailError: '',
            passwordError: '',
            cpasswordError: ''
        })

        try {
            setLoading(true)
            const response = await axios.post(`${BASE_URL}/register`, {
                name: formData.name,
                email: formData.email,
                password: formData.password,
                password_confirmation: formData.cpassword
            })

            toast('Register Successful', 'success')
            setTimeout(() => {
                setLoading(false)
                navigate('/sign-in')
            }, 2000)
        } catch (error) {
            if (error.response) {
                const errors = error.response.data.errors
                setErrorData({
                    nameError: errors.name ? errors.name[0] : '',
                    emailError: errors.email ? errors.email[0] : '',
                    passwordError: errors.password ? errors.password[0] : '',
                    cpasswordError: errors.password ? errors.password[1] : ''
                })
            } else {
                console.error('Error:', error)
            }
            setLoading(false)
        }
    }

    return (
        <>
            {loading && <SpinnerAntd />}
            <div className="flex w-full max-w-sm mx-auto overflow-hidden bg-white rounded-lg shadow-lg dark:bg-gray-800 lg:max-w-[1240px] my-10 md:my-24">
                <div className="hidden lg:block lg:w-1/2 border relative ">
                    <img className="" src={registerImg} alt="" height={'100%'} width={'100%'} />
                </div>
                <ToastContainer />
                <div className="w-full px-6 py-8 md:px-16 lg:w-1/2">
                    <div className="flex justify-center mx-auto">
                        <h2 className="font-bold text-2xl uppercase dark:text-white font-Inter">Sign Up</h2>
                    </div>
                    <p className="mt-3 text-xl text-center text-gray-600 dark:text-gray-200 font-Inter">
                        Sign Up to access your account
                    </p>
                    <form id="submitData" onSubmit={handleSubmit}>
                        <div className="mt-4">
                            <label className="block mb-2 text-sm font-medium text-gray-600 dark:text-gray-200 text-left font-Inter" htmlFor="name">Name</label>
                            <input
                                id="name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                className="block w-full px-4 py-2 text-gray-700 bg-white border rounded-lg dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring focus:ring-blue-300"
                                type="text"
                            />
                            {errorData.nameError && <label className="block mb-2 text-[12px] font-medium text-orange text-left font-Inter">{errorData.nameError}</label>}
                        </div>
                        <div className="mt-4">
                            <label className="block mb-2 text-sm font-medium text-gray-600 dark:text-gray-200 text-left font-Inter" htmlFor="email">Email Address</label>
                            <input
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                className="block w-full px-4 py-2 text-gray-700 bg-white border rounded-lg dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring focus:ring-blue-300"
                                type="email"
                            />
                            {errorData.emailError && <label className="block mb-2 text-[12px] font-medium text-orange text-left font-Inter">{errorData.emailError}</label>}
                        </div>
                        <div className="mt-4 relative">
                            <label className="block mb-2 text-sm font-medium text-gray-600 dark:text-gray-200 text-left font-Inter" htmlFor="password">Password</label>
                            <input
                                id="password"
                                name="password"
                                value={formData.password}
                                onChange={handleChange}
                                className="block w-full px-4 py-2 text-gray-700 bg-white border rounded-lg dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring focus:ring-blue-300"
                                type={showPassword ? 'text' : 'password'}
                            />
                            <button
                                type="button"
                                className="absolute right-2 top-8"
                                onClick={() => setShowPassword(!showPassword)}
                            >
                                {showPassword ? <VisibilityOffIcon/> : <VisibilityIcon/>}
                            </button>
                            {errorData.passwordError && <label className="block mb-2 text-[12px] font-medium text-orange text-left font-Inter">{errorData.passwordError}</label>}
                        </div>
                        <div className="mt-4 relative">
                            <label className="block mb-2 text-sm font-medium text-gray-600 dark:text-gray-200 text-left font-Inter" htmlFor="cpassword">Confirm Password</label>
                            <input
                                id="cpassword"
                                name="cpassword"
                                value={formData.cpassword}
                                onChange={handleChange}
                                className="block w-full px-4 py-2 text-gray-700 bg-white border rounded-lg dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring focus:ring-blue-300"
                                type={showConfirmPassword ? 'text' : 'password'}
                            />
                            <button
                                type="button"
                                className="absolute right-2 top-8"
                                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                            >
                                {showConfirmPassword ? <VisibilityOffIcon/> : <VisibilityIcon/>}
                            </button>
                            {errorData.cpasswordError && <label className="block mb-2 text-[12px] font-medium text-orange text-left font-Inter">{errorData.cpasswordError}</label>}
                        </div>
                        <div className="mt-6">
                            <button type="submit" className="w-full px-6 py-3 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-gray-800 rounded-lg hover:bg-gray-700 focus:outline-none focus:ring focus:ring-gray-300 focus:ring-opacity-50">
                                Sign Up
                            </button>
                        </div>
                    </form>
                    <div className="flex items-center justify-between mt-4">
                        <span className="w-1/5 border-b dark:border-gray-600 md:w-1/4"></span>
                        <Link className="text-xs text-gray-500 uppercase dark:text-gray-400 hover:underline" to={'/sign-in'}>or sign In</Link>
                        <span className="w-1/5 border-b dark:border-gray-600 md:w-1/4"></span>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SignUp
