import { ReadOutlined, SafetyOutlined, TrophyOutlined } from '@ant-design/icons';

const CurriculumData = [
  {
    icon: <ReadOutlined />,
    iconBackgroundColor: 'rgb(34 197 94)',
    title: 'Kindergarten',
    description: 'Counting objects, understanding concepts of inside and outside, comparing longer and shorter, learning letter names, and recognizing rhyming words.',
    color: 'text-green-500',
    subjects: [
      {
        name: 'Math',
        topics: [
          { title: 'Counting objects', description: 'Learn to count objects in various settings.' },
          { title: 'Comparing longer and shorter', description: 'Understand concepts of length and comparison.' },
          { title: 'Counting objects', description: 'Learn to count objects in various settings.' },
          { title: 'Comparing longer and shorter', description: 'Understand concepts of length and comparison.' },
          { title: 'Counting objects', description: 'Learn to count objects in various settings.' },
          { title: 'Comparing longer and shorter', description: 'Understand concepts of length and comparison.' },
        ]
      },
      {
        name: 'English',
        topics: [
          { title: 'Learning letter names', description: 'Recognize and name letters of the alphabet.' },
          { title: 'Recognizing rhyming words', description: 'Identify words that rhyme and their patterns.' }
        ]
      }
    ]
  },
  {
    icon: <SafetyOutlined />,
    iconBackgroundColor: 'rgb(59 130 246)',
    title: 'Elementary',
    description: 'Continuously evolving curriculum tailored to meet the educational needs of various age groups and learning levels.',
    color: 'text-blue-500',
    subjects: [
      {
        name: 'Math',
        topics: [
          { title: 'Basic arithmetic', description: 'Introduction to addition, subtraction, multiplication, and division.' },
          { title: 'Introduction to geometry', description: 'Basic geometric shapes and their properties.' }
        ]
      },
      {
        name: 'English',
        topics: [
          { title: 'Reading comprehension', description: 'Understand and interpret texts.' },
          { title: 'Basic grammar', description: 'Learn fundamental grammar rules and sentence structure.' }
        ]
      }
    ]
  },
  {
    icon: <TrophyOutlined />,
    iconBackgroundColor: 'rgb(34 197 94)',
    title: 'Middle School',
    description: 'Continuously evolving curriculum tailored to meet the educational needs of various age groups and learning levels.',
    color: 'text-green-500',
    subjects: [
      {
        name: 'Math',
        topics: [
          { title: 'Algebra', description: 'Introduction to algebraic concepts and equations.' },
          { title: 'Geometry', description: 'Advanced geometric concepts and problem-solving.' }
        ]
      },
      {
        name: 'English',
        topics: [
          { title: 'Advanced grammar', description: 'In-depth study of grammar rules and application.' },
          { title: 'Literary analysis', description: 'Analyze and interpret literary texts.' }
        ]
      }
    ]
  }
];

export default CurriculumData;
