import React from "react";
import { Link } from "react-router-dom";
import { RightOutlined } from "@ant-design/icons";
import CurriculumData from "../../data/curriculum/curriculumData";
import HeroSection from "../layout/heroSection/HeroSection";

export default function Curriculum() {
  return (
    <>
      <HeroSection img="https://royal-education-image.vercel.app/curriculum.png" title="Curriculum" description="Empowering Minds, Shaping Futures." />
      <div className="relative w-full">
        <div className="absolute inset-0 bg-bg-gray dark:bg-bg-gray"></div>
        <div className="container relative px-6 mx-auto md:max-w-[1240px] py-24">
          <div data-aos="fade-up" data-aos-delay="200">
            <h2 className="font-semibold text-3xl text-black font-Inter uppercase">
              Curriculum
            </h2>
            <p className="font-Inter mb-10 md:mx-20 mt-2">
              Our curriculum blends academic rigor with real-world applications, fostering critical thinking, creativity, and collaboration to prepare students for success.
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-5 justify-items-center w-full">
            {CurriculumData.map((item, index) => (
              <div
                key={index}
                data-aos="fade-up"
                data-aos-delay="200"
                className="rounded overflow-hidden shadow-lg bg-white dark:bg-bgdark my-4 md:my-0 p-5 dark:border dark:border-white flex flex-col justify-center items-center"
              >
                <div
                  style={{
                    fontSize: '42px',
                    color: '#fff',
                    borderRadius: '50%',
                    backgroundColor: item.iconBackgroundColor,
                    padding: '8px',
                    borderWidth: 5,
                    borderColor: item.iconBackgroundColor,
                    width: '60px',
                    height: '60px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: '10px',
                  }}
                >
                  {item.icon}
                </div>
                <div className="px-6 py-2 text-center">
                  <div
                    className={`font-bold text-xl mb-2 ${item.color} dark:text-white font-Inter-Bold`}
                  >
                    {item.title}
                  </div>
                  <p className="text-gray-700 text-base dark:text-darkwhite font-Inter-Light">
                    {item.description}
                  </p>
                </div>
                {item.subjects && item.subjects.length > 0 ? (
                  <div className="px-6">
                    <Link to={`/curriculum/${item.title}`} className="text-green-500 dark:text-darkwhite font-Inter">
                      View More <RightOutlined />
                    </Link>
                  </div>
                ) : (
                  <div className="px-6 text-gray-500 dark:text-darkwhite font-Inter">
                    No subjects available
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
