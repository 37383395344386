const NavbarData = [
    {
        id: 1,
        title: "Home Section",
        pathname: "/",
    },
    {
        id: 2,
        title: "Curriculum",
        pathname: "/curriculum",
    },
    {
        id: 3,
        title: "Book Store",
        pathname: "/book-store",
    },
    {
        id: 4,
        title: "Learning Resources",
        pathname: "/learnign-resources",
    },
    {
        id: 5,
        title: "Contact Us",
        pathname: "/contact-us",
    }
];

export default NavbarData;