import React, { useState } from 'react'
import AllGrade from './AllGrade';
import HeroSection from '../../layout/heroSection/HeroSection';
const LearningResources = () => {
  const [islogin, setIsLogin] = useState(false);

  React.useEffect(() => {
    if (localStorage.getItem("login")) {
      setIsLogin(true);
    }
  }, []);

  const handleClick = () => {
    window.location.href = '/sign-in';
  }

  return (
    <>
      <HeroSection img="https://royal-education-image.vercel.app/learningResources.png" title="Learning Resources" description="Fueling Curiosity, Enhancing Knowledge." />
      {
        islogin ? (
          <div className="container mx-auto max-w-[1240px] py-16 md:py-28">
            <div data-aos="fade-up" data-aos-delay="200">
              <h2 className="font-semibold text-3xl text-black font-Inter uppercase dark:text-white">Learning Resources</h2>
              <p className='font-Inter mb-10 md:mx-20 mt-2 dark:text-darkwhite'>Our learning resources combine engaging content with interactive tools, enhancing understanding and retention while promoting independent learning and problem-solving skills.</p>
            </div>
            <AllGrade />
          </div>
        ) : (
          <div className="container mx-auto max-w-[1240px] py-16 md:py-28">
            <h2 className="font-semibold text-3xl text-black font-Inter  dark:text-white">Please login to access learning resources</h2>
            <p className='py-1 mt-5 px-3 rounded-md inline-block bg-blue cursor-pointer text-white hover:bg-white hover:text-blue hover:border' onClick={handleClick}>Go to Login?</p>
          </div>
        )
      }
    </>
  )
}

export default LearningResources;

