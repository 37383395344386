import React from 'react';

const HeroSection = ({ img, title, description }) => {
  return (
    <div
      className="relative w-full h-44 md:h-[40vh] bg-cover bg-center"
      style={{ backgroundImage: `url(${img})` }}
    >
      <div className="absolute inset-0 bg-black opacity-60"></div>
      <div className="absolute inset-0 flex justify-center items-center">
        <div className="justify-items-center text-center">
          <h2 className="text-white font-bold text-2xl md:text-5xl font-Inter">
            {title}
          </h2>
          <p className="text-white mt-2 text-base md:text-xl font-Inter">
            {description}
          </p>
        </div>
      </div> 
    </div>
  );
};

export default HeroSection;
