import React  from 'react'
import { AreaChartOutlined, DatabaseOutlined, FieldTimeOutlined, HomeOutlined, MobileOutlined, ReadOutlined, RightOutlined, UserOutlined } from '@ant-design/icons'
import HeroSection from '../../components/home/HeroSection'
import flexible from '../../assets/images/Flexible.png'
import { EnergySavingsLeafOutlined, FunctionsOutlined, HelpCenterOutlined, InsightsOutlined, SchoolOutlined } from '@mui/icons-material'
import CurriculumData from '../../data/curriculum/curriculumData'
import { Link } from 'react-router-dom'

const Home = () => {

  return (
    <>
      <HeroSection />
      {/* start Curriculum section */}
      <div className='bg-bg-gray pb-20'>
        <div className='container relative px-6 py-16 md:py-24 mx-auto md:max-w-[1240px] '>
          <div data-aos="fade-up" data-aos-delay="200">
            <h2 className="font-semibold text-3xl mt-10 text-black font-Inter uppercase">Curriculum</h2>
            <p className='font-Inter mb-10 md:mx-20 mt-2'>Our curriculum blends academic rigor with real-world applications, fostering critical thinking, creativity, and collaboration to prepare students for success.</p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-5  justify-items-center w-full  ">
            {
              CurriculumData.map((item, index) => (
                <div
                  key={index}
                  data-aos="fade-up"
                  data-aos-delay="200"
                  className="rounded overflow-hidden shadow-lg bg-white dark:bg-bgdark my-4 md:my-0 p-5 dark:border dark:border-white flex flex-col justify-center items-center"
                >
                  <div
                    style={{
                      fontSize: '42px',
                      color: '#fff',
                      borderRadius: '50%',
                      backgroundColor: item.iconBackgroundColor,
                      padding: '8px',
                      borderWidth: 5,
                      borderColor: item.iconBackgroundColor,
                      width: '60px',
                      height: '60px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginBottom: '10px',
                    }}
                  >
                    {item.icon}
                  </div>
                  <div className="px-6 py-2 text-center">
                    <div className={`font-bold text-xl mb-2 ${item.color} dark:text-white font-Inter-Bold`}>
                      {item.title}
                    </div>
                    <p className="text-gray-700 text-base dark:text-darkwhite font-Inter-Light">
                      {item.description}
                    </p>
                  </div>
                  {item.subjects && item.subjects.length > 0 ? (
                  <div className="px-6">
                    <Link to={`/curriculum/${item.title}`} className="text-green-500 dark:text-darkwhite font-Inter">
                      View More <RightOutlined />
                    </Link>
                  </div>
                ) : (
                  <div className="px-6 text-gray-500 dark:text-darkwhite font-Inter">
                    No subjects available
                  </div>
                )}
                </div>
              ))}
          </div>
        </div>
      </div>
      {/* end Curriculum section */}

      {/* section  */}
      <div data-aos="fade-up" data-aos-delay="200" className=' bg-bgdark'>
        <div className="container min-h-[80vh] mx-auto px-6 py-20 max-w-[1240px] flex justify-center items-center  dark:text-bermuda dark:rounded-md">
          <div className="flex flex-col lg:flex-row  rounded-lg gap-10 shadow-lg items-center">
            <div data-aos="fade-up" data-aos-delay="200" className='h-[600] md:w-1/2 '>
              <img src={flexible} alt="" className='h-[600] w-[450] rounded-2xl' />
            </div>
            <div data-aos="fade-up" data-aos-delay="200" className='ml-5 md:w-1/2'>
              <h2 className='font-Poppins-Bold md:text-5xl text-2xl py-2 text-white text-left mb-5'>Flexible Learning Based on Your Child's Unique Needs</h2>
              <p className='font-Inter-Light text-xl text-white text-left'>Our platform is designed to make home education engaging and manageable. By focusing on your child's individual needs, we help bridge learning gaps and build confidence.</p>
            </div>
          </div>
        </div>
      </div>
      {/* section  */}

      {/*start benefit section  */}
      <div className=''>
        <div className="relative w-full   ">
          <div className="absolute inset-0 bg-bg-gray dark:bg-bg-gray"></div>
          <div className='container relative px-6 py-24 md:py-36 mx-auto md:max-w-[1240px] inset-0  '>
            <div data-aos="fade-up" data-aos-delay="200">
              <h2 className="font-semibold text-3xl  text-black font-Inter uppercase">BENEFITS</h2>
              <p className='font-Inter mb-10 md:mx-32 mt-2'>Royal home education offers personalized learning, fosters individualized growth, and ensures a tailored, enriching academic journey.</p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-5  justify-items-center w-full ">
              <div data-aos="fade-up" data-aos-delay="200" className="max-w-sm md:max-w-none rounded overflow-hidden shadow-lg bg-white dark:bg-bgdark my-4 md:my-0 p-5  ">
                <AreaChartOutlined style={{
                  fontSize: '42px',
                  color: '#fff',
                  borderRadius: '50%',
                  backgroundColor: 'rgb(239 68 68)',
                  padding: '8px',
                  borderWidth: 5,
                  borderColor: 'rgb(239 68 68)'
                }}
                  className='dark:bg-bgdark'
                />
                <div className="px-6 py-4">
                  <div className="font-bold text-xl mb-2 text-red-500 dark:text-white font-Inter-Bold">Real-Time Analytics</div>
                  <p className="text-gray-700 text-base dark:text-darkwhite font-Inter-Light">
                    Stay updated on children's progress with real-time, relevant data.
                  </p>
                </div>
              </div>
              <div data-aos="fade-up" data-aos-delay="200" className="max-w-sm md:max-w-none rounded overflow-hidden shadow-lg bg-white dark:bg-bgdark my-4 md:my-0 p-5 ">
                <DatabaseOutlined style={{
                  fontSize: '42px',
                  color: '#fff',
                  borderRadius: '50%',
                  backgroundColor: 'rgb(59 130 246)',
                  padding: '8px',
                  borderWidth: 5,
                  borderColor: 'rgb(59 130 246)'
                }} />
                <div className="px-6 py-4">
                  <div className="font-bold text-xl mb-2 text-blue-500 dark:text-white font-Inter-Bold">Data Transformation</div>
                  <p className="text-gray-700 text-base dark:text-darkwhite font-Inter-Light">
                    Convert practice data into straightforward insights with just a few clicks.
                  </p>
                </div>
              </div>
              <div data-aos="fade-up" data-aos-delay="200" className="max-w-sm md:max-w-none rounded overflow-hidden shadow-lg bg-white dark:bg-bgdark my-4 md:my-0 p-5">
                <FieldTimeOutlined style={{
                  fontSize: '42px',
                  color: '#fff',
                  borderRadius: '50%',
                  backgroundColor: 'rgb(34 197 94)',
                  padding: '8px',
                  borderWidth: 5,
                  borderColor: 'rgb(34 197 94)'
                }} />
                <div className="px-6 py-4">
                  <div className="font-bold text-xl mb-2 text-green-500 dark:text-white font-Inter-Bold">Time-Saving Reports</div>
                  <p className="text-gray-700 text-base dark:text-darkwhite font-Inter-Light">
                    Receive practical details to select the best teaching strategies.
                  </p>
                </div>
              </div>
              <div data-aos="fade-up" data-aos-delay="200" className="max-w-sm md:max-w-none rounded overflow-hidden shadow-lg bg-white dark:bg-bgdark my-4 md:my-0 p-5  ">
                <ReadOutlined style={{
                  fontSize: '42px',
                  color: '#fff',
                  borderRadius: '50%',
                  backgroundColor: 'rgb(239 68 68)',
                  padding: '8px',
                  borderWidth: 5,
                  borderColor: 'rgb(239 68 68)'
                }}
                  className='dark:bg-bgdark'
                />
                <div className="px-6 py-4">
                  <div className="font-bold text-xl mb-2 text-red-500 dark:text-white font-Inter-Bold">NCERT Syllabus Coverage</div>
                  <p className="text-gray-700 text-base dark:text-darkwhite font-Inter-Light">
                    Skills align with the National Council of Education Research and Training Syllabus.
                  </p>
                </div>
              </div>
              <div data-aos="fade-up" data-aos-delay="200" className="max-w-sm md:max-w-none rounded overflow-hidden shadow-lg bg-white dark:bg-bgdark my-4 md:my-0 p-5 ">
                <MobileOutlined style={{
                  fontSize: '42px',
                  color: '#fff',
                  borderRadius: '50%',
                  backgroundColor: 'rgb(59 130 246)',
                  padding: '8px',
                  borderWidth: 5,
                  borderColor: 'rgb(59 130 246)'
                }} />
                <div className="px-6 py-4">
                  <div className="font-bold text-xl mb-2 text-blue-500 dark:text-white font-Inter-Bold">Flexible Learning</div>
                  <p className="text-gray-700 text-base dark:text-darkwhite font-Inter-Light">
                    Tailored learning experiences based on each child's unique needs.
                  </p>
                </div>
              </div>
              <div data-aos="fade-up" data-aos-delay="200" className="max-w-sm md:max-w-none rounded overflow-hidden shadow-lg bg-white dark:bg-bgdark my-4 md:my-0 p-5">
                <UserOutlined style={{
                  fontSize: '42px',
                  color: '#fff',
                  borderRadius: '50%',
                  backgroundColor: 'rgb(34 197 94)',
                  padding: '8px',
                  borderWidth: 5,
                  borderColor: 'rgb(34 197 94)'
                }} />
                <div className="px-6 py-4">
                  <div className="font-bold text-xl mb-2 text-green-500 dark:text-white font-Inter-Bold">Parent Empowered</div>
                  <p className="text-gray-700 text-base dark:text-darkwhite font-Inter-Light">
                    Empower parents to take control of their child's education with our award-winning PreK-12th programs.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end benefit section  */}

      {/*start core feature section */}
      <div className="relative w-full">
        <div className="absolute inset-0 bg-cover bg-no-repeat bg-fixed  bg-[url('https://royal-education-image.vercel.app/study-with-laptop.jpg')]"></div>
        <div className="absolute inset-0 bg-black dark:bg-bg-gray opacity-40"></div>
        <div className="relative container min-h-[70vh] flex  items-center mx-auto py-5 max-w-[1240px]">
          <div class="grid grid-cols-1 md:grid-cols-2 rounded py-5 ">
            <div class="md:px-6 px-3 py-2 grid grid-cols-2 md:grid-cols-3 gap-10">
              <div data-aos="fade-up" data-aos-delay="200" className='h-[150px] w-[150px] bg-custom flex  items-center rounded-2xl cursor-pointer '>
                <div className='justify-self-center items-center '>
                  <InsightsOutlined
                    style={{
                      fontSize: '50px',
                      color: '#fff',
                      padding: '8px',
                    }}
                  />
                  <h3 className='text-white font-Poppins-SemiBold'>Immediate Performance Insights</h3>
                </div>
              </div>
              <div data-aos="fade-up" data-aos-delay="200" className='h-[150px] w-[150px] bg-blue flex  items-center rounded-2xl cursor-pointer'>
                <div className='justify-self-center items-center'>
                  <HelpCenterOutlined
                    style={{
                      fontSize: '50px',
                      color: '#fff',
                      padding: '8px',
                    }}
                  />
                  <h3 className='text-white font-Poppins-SemiBold'>Valuable Information</h3>
                </div>
              </div>
              <div data-aos="fade-up" data-aos-delay="200" className='h-[150px] w-[150px] bg-gray flex  items-center rounded-2xl cursor-pointer'>
                <div className='justify-self-center items-center'>
                  <EnergySavingsLeafOutlined
                    style={{
                      fontSize: '50px',
                      color: '#fff',
                      padding: '8px',
                    }}
                  />
                  <h3 className='text-white font-Poppins-SemiBold px-1'> Efficient Teaching Strategies</h3>
                </div>
              </div>
              <div data-aos="fade-up" data-aos-delay="200" className='h-[150px] w-[150px] bg-orange flex  items-center rounded-2xl cursor-pointer'>
                <div className='justify-self-center items-center'>
                  <FunctionsOutlined
                    style={{
                      fontSize: '50px',
                      color: '#fff',
                      padding: '8px',
                    }}
                  />
                  <h3 className='text-white font-Poppins-SemiBold'>Aligned with India's Maths Curriculum    </h3>
                </div>
              </div>
              <div data-aos="fade-up" data-aos-delay="200" className='h-[150px] w-[150px] bg-purple flex  items-center rounded-2xl cursor-pointer'>
                <div className='justify-self-center items-center'>
                  <HomeOutlined
                    style={{
                      fontSize: '28px',
                      color: '#fff',
                      padding: '8px',
                    }}
                  />
                  <h3 className='text-white font-Poppins-SemiBold'>Enhanced Home Education</h3>
                </div>
              </div>
              <div data-aos="fade-up" data-aos-delay="200" className='h-[150px] w-[150px] bg-nav flex  items-center rounded-2xl cursor-pointer'>
                <div className='justify-self-center items-center'>
                  <SchoolOutlined
                    style={{
                      fontSize: '50px',
                      color: '#fff',
                      padding: '8px',
                    }}
                  />
                  <h3 className='text-white font-Poppins-SemiBold'>Homeschool Curriculum</h3>
                </div>
              </div>
            </div>
            <div class="px-5 pb-2 flex items-center justify-center md:justify-normal">
              <div data-aos="fade-up" data-aos-delay="200">
                <h2 className="md:px-24 mt-5 md:text-4xl text-3xl font-Inter-Bold mr-2 mb-2 text-white">
                  Core Features
                </h2>
                <p className='font-Inter text-white'>Our core features include personalized learning, tailored academic growth, comprehensive homeschooling curriculum, and aligned educational strategies.
                </p>
                <div data-aos="fade-up" data-aos-delay="200" class="px-6 pb-2 pt-10">
                  <a href="#test" className=' hover:bg-white hover:text-black text-white font-Inter border py-4 px-12  rounded-md'>Try Now</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*end core feature section */}

    </>
  )
}

export default Home
