import React from 'react'
import TermData from '../../data/policyData/TermData.js'
import HeroSection from '../layout/heroSection/HeroSection.js'

const TermAndService = () => {
    const Heading = ({ heading }) => {
        return (
            <h2 className='font-Inter font-semibold text-lg mb-3 dark:text-white'>{heading}</h2>
        )
    }
    const SubHeading = ({ subHeading }) => {
        return (
            <p className='text-base font-semibold font-Inter mb-3 dark:text-white'>{subHeading}</p>
        )
    }
    const Text = ({ text }) => {
        return (
            <p className='text-sm font-Inter mb-3 dark:text-white'>{text}</p>
        )
    }
    const List = ({ items }) => (
        <ul className='list-disc ml-12 font-Inter text-sm mb-3 dark:text-white'>
            {items.map((item, index) => <li key={index}>{item}</li>)}
        </ul>
    );
    const ContactInfo = ({ contactinfo }) => {
        return (
            <p className='text-[12px] font-Inter dark:text-white'>{contactinfo}</p>
        )
    }
    return (
        <>
            <HeroSection img="https://royal-education-image.vercel.app/term-service.png" title="Term & Service" description="Guiding Principles, Clear Expectations." />

            <div className="container relative px-6 mx-auto md:max-w-[1240px] py-10">
                <h2 className='text-2xl font-Inter font-semibold underline mb-7 dark:text-white'>Term & Service </h2>
                <div className='text-left'>
                    <SubHeading subHeading={'Terms of Use/Service for Royal Education'} />
                    <Text text={'Effective Date: 20th June, 2024 '} />
                    <p className='text-sm font-Inter mb-3 dark:text-white'>Welcome to Royal Education! These Terms of Use ("Terms") govern your use of our website <a href="https://royalhomeschool.in" className='text-blue underline'>[https://royalhomeschool.in]</a> ("Website") and the educational services provided by Royal Education ("we", "us", "our"). By accessing or using our Website and services, you agree to comply with and be bound by these Terms. If you do not agree with these Terms, please do not access or use our Website.</p>
                    <div>
                        {TermData.map((section, index) => (
                            <div key={index}>
                                <Heading heading={section.heading} />
                                {section.subSections ? section.subSections.map((subSection, subIndex) => (
                                    <div key={subIndex}>
                                        <SubHeading subHeading={subSection.subHeading} />
                                        <Text text={subSection.text} />
                                        {subSection.listItems && <List items={subSection.listItems} />}
                                    </div>
                                )) : <Text text={section.text} />}
                            </div>
                        ))}
                    </div>
                    <Heading heading={'11. Contact Us'} />
                    <Text text={'If you have any questions, concerns, or requests regarding these Terms of Use/Service, please contact us at:'} />
                    <ContactInfo contactinfo={'Royal Education'} />
                    <ContactInfo contactinfo={'Vithoda, Ta.: Kheralu,'} />
                    <ContactInfo contactinfo={'Dist.: Mehsana - 384325'} />
                    <ContactInfo contactinfo={'Email: support@royalhomeschool.in'} />
                </div>
            </div>
        </>
    )
}

export default TermAndService;
