import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import NavbarData from "../../../data/NavbarData";
import { Link, useNavigate } from "react-router-dom";
import { MoonOutlined, SunOutlined } from "@ant-design/icons";
import logo from "../../../assets/images/logo.png";
import { Container } from "@mui/material";
import ProfileDropDown from "../../../components/home/ProfileDropDown";
import profiledata from "../../../data/api/apiData";

const drawerWidth = 240;

function TopbarHeader(props) {
  const navigate = useNavigate();
  const [dark, setDark] = React.useState(false);
  const [isLogin , setIsLogin] = React.useState(false);
  const [data , setData] = React.useState(null);

  React.useEffect(() => {
    if (localStorage.getItem("login")) {
        setIsLogin(true);
        const fetchProfile = async () => {
          const profile = await profiledata();
          setData(profile?.profile);
      };
    
      fetchProfile();
    }
}, []);


    
  const darkModeHandler = () => {
    setDark(!dark);
    document.body.classList.toggle("dark");
  };

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{ textAlign: "center" }}
      className="h-screen dark:bg-bgdark dark:text-white"
    >
      <Typography
        variant="h6"
        sx={{ my: 2 }}
        onClick={() => navigate("/")}
        className=""
      >
        <img className="w-auto h-10 mx-12" src={logo} alt="Logo" />
      </Typography>
      <Divider className="text-white dark:text-white" />
      <List>
        {NavbarData.map((item) => (
          <ListItem key={item.id} disablePadding>
            <ListItemButton
              sx={{ textAlign: "center", fontFamily: "Poppins" }}
            >
              <Link to={item.pathname}><ListItemText primary={item.title} /></Link>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      {
        !isLogin &&(
          <div className=" flex justify-end pt-3 gap-3 mr-5">
            
            
        <Button variant="outlined" ><Link to={'/sign-in'}>Sign In</Link></Button>
        <Button variant="outlined" ><Link to={'/sign-up'}>Sign Up</Link></Button>
      </div>
        )
      }
      
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      <CssBaseline />
      <AppBar
        position="relative"
        sx={{ bgcolor: "white", color: "black" }}
        className="dark:bg-bgdark"
      >
        <Container>
          <Toolbar sx={{ flex: 1, justifyContent: "space-between" }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{
                mr: 2,
                display: { sm: "none" },
                color: dark ? "#fff" : "#000",
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              variant="h6"
              component="div"
              sx={{ display: { xs: "none", sm: "block" }, cursor: "pointer" }}
              onClick={() => navigate("/")}
            >
              <img className="w-auto h-6 sm:h-12" src={logo} alt="Logo" />
            </Typography>
            <Box
              sx={{
                display: { xs: "none", sm: "block" },
                justifyContent: "space-between",
              }}
            >
              {isLogin ? (
                <div className="flex justify-end pt-2 gap-3 mr-5">
                <ProfileDropDown data={data} />
                </div>
            ) : (
              <div className="flex justify-end pt-2 gap-3 mr-5"> 
              <Button 
                  variant="outlined" 
                  size="small" 
                  color="primary" 
                  className="dark:bg-white font-Inter text-sm"
              >
                  <Link to={'/sign-in'}>Sign In</Link>
              </Button>
              <Button 
                  variant="outlined" 
                  size="small" 
                  color="primary"  
                  className="dark:bg-white font-Inter text-sm"
              >
                  <Link to={'/sign-up'}>Sign Up</Link>
              </Button>
          </div>
            )}
              {NavbarData.map((item) => (
                <Button
                  key={item.id}
                  sx={{ color: "#000", fontFamily: "Inter", padding: 2 }}
                  className="dark:text-white"
                  onClick={() => navigate(item.pathname)}
                >
                  {item.title}
                </Button>
              ))}
              <Button onClick={() => darkModeHandler()}>
                {dark && (
                  <SunOutlined
                    style={{ fontSize: 24, color: "#fff", padding: 2 }}
                  />
                )}
                {!dark && (
                  <MoonOutlined
                    style={{ fontSize: 24, color: "#000", padding: 2 }}
                  />
                )}
              </Button>
            </Box>
           <div>
        
           <Button
              onClick={() => darkModeHandler()}
              sx={{ display: { sm: "none" } }}
            >
              {dark && <SunOutlined style={{ fontSize: 24, color: "#fff" }} />}
              {!dark && (
                <MoonOutlined style={{ fontSize: 24, color: "#000" }} />
              )}
            </Button>
            {
              isLogin && (
                <Button
              sx={{ display: { sm: "none" } }}
            >
            <ProfileDropDown data={data} />
            </Button>
              )
            }
           </div>
          </Toolbar>
        </Container>
      </AppBar>
      <nav>
        <Drawer
          className="bg-bgdark"
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </>
  );
}

TopbarHeader.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default TopbarHeader;
