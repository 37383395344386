import React from "react";
import { Link, useParams } from "react-router-dom";
import CurriculumData from "../../data/curriculum/curriculumData";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { RightOutlined } from "@ant-design/icons";
import HeroSection from "../layout/heroSection/HeroSection";

const CurriculumSubject = () => {
  const { title } = useParams();
  const curriculum = CurriculumData.find((item) => item.title === title);

  if (!curriculum || !curriculum.subjects || curriculum.subjects.length === 0) {
    return <div>No subjects available for {title}</div>;
  }

  return (
    <>
      
      <HeroSection img="https://royal-education-image.vercel.app/curriculum.png" title={curriculum.title} description={curriculum.description} />
      <div className="container px-6 mx-auto md:max-w-[1240px] py-24">
        <h2 className="font-semibold text-3xl text-black font-Inter uppercase">
          {curriculum.title} Subjects
        </h2>
        <p className="mt-3 text-gray-500 dark:text-gray-300">
          {curriculum.description}
        </p>
        <ul className="grid grid-cols-1 gap-8 mt-8 xl:mt-12 xl:gap-12 md:grid-cols-2 text-left">
          {curriculum.subjects.map((subject, index) => (
            <li key={index} className="p-6 border rounded-xl border-r-gray-200 dark:border-gray-700">
              <div className="md:flex md:items-start md:-mx-4">
                <span className="inline-block p-2 text-white bg-blue rounded-xl md:mx-4">
                  <CheckCircleIcon />
                </span>
                <div className="mt-4 md:mx-4 md:mt-0">
                  <h1 className="text-xl font-medium text-gray-700 capitalize dark:text-white">
                    {subject.name}
                  </h1>
                  {subject.topics && subject.topics.length > 0 ? (
                    <>
                      <p className="mt-3 text-gray-500 dark:text-gray-300">
                        {subject.description}
                      </p>
                      <Link to={`/curriculum/${title}/subject/${subject.name}`} className="text-green-500 dark:text-darkwhite font-Inter mt-3 block">
                        View More <RightOutlined />
                      </Link>
                    </>
                  ) : (
                    <p className="text-gray-500 dark:text-gray-300 mt-3">(No topics available)</p>
                  )}
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </>

  );
};

export default CurriculumSubject;
