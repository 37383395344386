import { FacebookFilled, GithubFilled, GoogleCircleFilled, InstagramFilled, LinkedinFilled, MailFilled, PhoneFilled } from '@ant-design/icons'
import { Divider } from '@mui/material'
import React from 'react'

const FooterHeader = () => {
  return (
    <>
      <div className='w-full h-20 md:h-10 flex justify-center items-center' style={{ backgroundColor: '#604CAF' }} >
        <div className='container mx-auto px-6 py-3 md:max-w-[1240px] sm:flex sm:justify-between  items-center'>
          <div className='text-white font-InterBold mb-3 md:mb-0 dark:text-darkwhite'>Get connected with us on social networks:</div>
          <div className='text-white'>
            <ul className='flex justify-center items-center gap-4 '>
              <li> <a href="#df"><FacebookFilled className='text-xl dark:text-darkwhite ' /></a> </li>
              <li> <a href="#df"><InstagramFilled className='text-xl dark:text-darkwhite' /></a> </li>
              <li> <a href="#df"><GoogleCircleFilled className='text-xl dark:text-darkwhite' /></a> </li>
              <li> <a href="#df"><LinkedinFilled className='text-xl dark:text-darkwhite' /></a> </li>
              <li> <a href="#df"><GithubFilled className='text-xl dark:text-darkwhite' /></a> </li>
            </ul>
          </div>
        </div>
      </div>
      <div className='footer text-left bg-white dark:bg-bgdark dark:text-darkwhite'>
        <div className='container mx-auto px-6 py-3 md:max-w-[1240px] grid grid-cols-1 md:grid-cols-4 gap-5 text-center md:text-left'>
          <div className=''>
            <h2 className='text-base font-bold mb-6 underline font-Inter-Bold uppercase'>Royal Education</h2>
            <p className='pr-5 font-Inter'>Royal Education is dedicated to providing immediate, insightful, and actionable data on children's academic performance.</p>
          </div>

          <div>
            <h2 className='text-base font-bold mb-6 underline font-Inter-Bold uppercase'>USEFUL LINKS</h2>
            <ul>
              <li className='mb-2 font-Inter hover:underline decoration-2'><a href='/'>Kindergarten</a></li>
              <li className='mb-2 font-Inter hover:underline decoration-2'><a href='#e'>Elementary</a></li>
              <li className='mb-2 font-Inter hover:underline decoration-2'><a href='#f'>Middle School</a></li>
              <li className='mb-2 font-Inter hover:underline decoration-2'><a href='/testimonials'>Testimonials</a></li>
              <li className='mb-2 font-Inter hover:underline decoration-2'><a href='/faqs'>FAQs</a></li>
            </ul>
          </div>

          <div>
            <h2 className='text-base font-bold mb-6 underline font-Inter-Bold uppercase'>Privacy & Policy</h2>
            <ul>
              {/* <li className='mb-2 font-Inter hover:underline decoration-2'><a href='#f'>Memberships</a></li> */}
              <li className='mb-2 font-Inter hover:underline decoration-2'><a href='#f'>Technical & Supports</a></li>
              <li className='mb-2 font-Inter hover:underline decoration-2'><a href='/privacy-policy'>Privacy & Policy</a></li>
              <li className='mb-2 font-Inter hover:underline decoration-2'><a href='/term-service'>Terms & Service</a></li>
              {/* <li className='mb-2 font-Inter hover:underline decoration-2'><a href='#f'>Evaluation</a></li> */}
              
            </ul>
          </div>

          <div className='flex flex-col items-center md:items-start'>
            <h2 className='text-base font-bold mb-6 underline font-Inter-Bold uppercase'>CONTACT</h2>
            <ul className='gap-5 items-center'>
              {/* <li className='mb-2 hover:underline decoration-2'><a href='https://www.google.com/maps/place/Vithoda,+Gujarat/@23.9198671,72.5477695,17z/data=![…]1!8m2!3d23.9193013!4d72.5515384!16s%2Fg%2F1hhvsbvx_?entry=ttu' className='flex justify-start gap-2 '>
                <HomeFilled />
                <p className='font-Inter text-left max-w-48'>Vithoda, Ta: kheralu, Dist: Mehsana - 384325</p>
              </a></li> */}
              <li className='mb-2 hover:underline decoration-2'><a href='mailto:rakkeshrajgor@gmail.com' className='flex justify-start gap-2 items-center'>
                <MailFilled />
                <p className='font-Inter'>rakkeshrajgor@gmail.com</p>
              </a></li>
              <li className='mb-2 hover:underline decoration-2'><a href='tel:+91 99747 60205' className='flex justify-start gap-2 items-center'>
                <PhoneFilled />
                <p className='font-Inter'>+91 99747 60205</p>
              </a></li>
            </ul>
          </div>
        </div>

      </div>
      <Divider />
      <div className='w-full h-30 flex justify-center items-center bg-white dark:bg-copyrightbg my-2 md:my-0 '>
        <div className='container mx-auto  align-middle '> 
          <p className='dark:text-darkwhite text-copyrightbg font-Inter my-3'>Exploring our site and using our software implies consent to our Universal Terms of Service. By doing so, you are bound to our GDPR Policy available at <a href="https://gdpr.eu." className='text-blue hover:underline decoration-2'>https://gdpr.eu.</a></p>
          <p className='dark:text-darkwhite text-copyrightbg font-Inter'>© 2024 Royal Education. Design and Developed by <a href="https://webnx.in/" target='_blank' className='text-blue hover:underline decoration-2' >WebNX</a></p>
        </div>
      </div>
    </>
  )
}

export default FooterHeader
