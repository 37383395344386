import React from 'react'
import HeroSection from '../layout/heroSection/HeroSection'

const BookStore = () => {
  return (
    <>
      <HeroSection img='https://royal-education-image.vercel.app/bookstore.png' title='Book Store' description='Inspiring Reads, Expanding Horizons.' />
      <div className='h-[550px] bg-bgdark text-white flex justify-center items-center text-4xl'>
        Comming soon
      </div>


    </>
  )
}

export default BookStore
