import axios from 'axios';
import React, { useEffect, useState } from 'react';
import BASE_URL from '../../config/config';
import { Link } from 'react-router-dom';

const SidebarGrade = () => {
  const [grades, setGrades] = useState([]);

  useEffect(() => {
    const fetchGrades = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/class`);
        setGrades(response.data);
      } catch (err) {
        console.error('Failed to fetch grades:', err);
      }
    };

    fetchGrades();
  }, []);

  return (
    <div className="w-auto">
      <div className="nav-container">
        <h3 className='dark:text-white'>Grade</h3>
        {grades.map(grade => {
          const gradeName = grade.name || '';
          const lastWord = gradeName.split(' ').pop();
          
          return (
            <Link className="nav-item" to={`/${gradeName}/subject/${grade.id}`} key={grade.id}>
              {lastWord}
              <span>{gradeName}</span>
            </Link>
          );
        })}
      </div>
    </div>
  );
}

export default SidebarGrade;
