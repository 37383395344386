import React, { useState } from "react";
import { useParams } from "react-router-dom";
import CurriculumData from "../../data/curriculum/curriculumData";
import HeroSection from "../layout/heroSection/HeroSection";

const CurriculumTopic = () => {
  const { title, subjectName } = useParams();
  const curriculum = CurriculumData.find((item) => item.title === title);
  const subject = curriculum?.subjects.find((sub) => sub.name === subjectName);

  const [openIndex, setOpenIndex] = useState(null);

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  if (!subject || !subject.topics || subject.topics.length === 0) {
    return <div>No topics available for {subjectName}</div>;
  }

  return (
    <>
    
      <HeroSection img="https://royal-education-image.vercel.app/curriculum.png" title={subject.name} description={subject.description} />
      <section className="bg-white dark:bg-gray-900">
        <div className="container max-w-[1240px] px-6 py-10 mx-auto">
          <h1 className="text-2xl font-semibold text-center text-gray-800 lg:text-3xl dark:text-white">
            {subject.name} Topics
          </h1>
          <div className="mt-12 grid grid-cols-1 md:grid-cols-2 gap-6">
            {subject.topics.map((topic, index) => (
              <div
                key={index}
                className="border-2 border-gray-100 rounded-lg dark:border-gray-700"
              >
                <button
                  className="flex items-center justify-between w-full p-4"
                  onClick={() => handleToggle(index)}
                >
                  <h1 className="font-semibold text-gray-700 dark:text-white" title='view'>{topic.title}</h1>
                  <span
                    className={`text-gray-400 bg-gray-200 rounded-full transition-transform ${openIndex === index ? "rotate-180" : ""
                      }`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d={`M${openIndex === index ? "12 6v12m6-6H6" : "18 12H6"}`}
                      />
                    </svg>
                  </span>
                </button>
                {openIndex === index && (
                  <>
                    <hr className="border-gray-200 dark:border-gray-700" />
                    <p className="p-4 text-sm text-gray-500 dark:text-gray-300 text-left">
                      {topic.description}
                    </p>
                  </>
                )}
              </div>
            ))}
          </div>
        </div>
      </section>
    </>

  );
};

export default CurriculumTopic;
