// profiledata.js
import axios from "axios";
import BASE_URL from "../../config/config";

const profiledata = async () => {
    const id = localStorage.getItem("id");
    if (!id) {
        console.error("No user found.");
        return null;
    }

    try {
        const profile = await axios.get(`${BASE_URL}/profile/${id}`);
        console.log(profile);
        return profile.data; // return the data part of the response
    } catch (error) {
        console.error("Error fetching profile data:", error);
        return null;
    }
};

export default profiledata;
