import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import axios from 'axios';
import SpinnerAntd from '../spinner/SpinnerAntd';
import profiledata from '../../data/api/apiData';
import BASE_URL from '../../config/config';

const EditProfile = () => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [imagePreview, setImagePreview] = useState('');

    useEffect(() => {
        const fetchProfile = async () => {
            setLoading(true);
            const profile = await profiledata();
            setData(profile);
            setImagePreview(profile.imageUrl);
            setLoading(false);
        };

        fetchProfile();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setData((prevData) => ({
            ...prevData,
            profile: {
                ...prevData.profile,
                [name]: value,
            },
        }));
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
            setImagePreview(reader.result);
            setData((prevData) => ({
                ...prevData,
                profile: {
                    ...prevData.profile,
                    imageUrl: reader.result,
                },
            }));
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const handleUpdateProfile = async () => {
        setLoading(true);
        try {
            await axios.post(`${BASE_URL}/profile/update`, data.profile);
            alert('Profile updated successfully');
            window.history.back();
        } catch (error) {
            console.error('Error updating profile', error);
            alert('Failed to update profile');
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return <SpinnerAntd />;
    }

    return (
        <>
            <div className="relative w-full h-44 md:h-[40vh] bg-[url('https://royal-education-image.vercel.app/curriculum.png')] bg-cover bg-center">
                <div className="absolute inset-0 bg-black opacity-60"></div>
                <div className="absolute inset-0 flex justify-center items-center">
                    <div className="justify-items-center">
                        <h2 className="text-white font-bold text-2xl md:text-5xl font-Inter">Edit Profile</h2>
                    </div>
                </div>
            </div>
            <section className="bg-white dark:bg-gray-900 py-24">
                <div className="container items-center justify-center px-6 mx-auto md:max-w-[1240px]">
                    <div className="bg-white overflow-hidden shadow rounded-lg border pb-5">
                        <div className="px-4 py-5 sm:px-6">
                            <h3 className="text-lg leading-6 font-medium text-gray-900">Edit Profile</h3>
                            <div className="md:flex md:justify-start items-center gap-5">
                                <img src={imagePreview} height={'150px'} width={'150px'} alt="profile img" className="rounded-full border-2 border-gray-300 mb-5" />
                                <input type="file" name="profileImg" onChange={handleImageChange} />
                            </div>
                        </div>
                        <div className="border-t border-gray-200 px-4 py-5 sm:p-0 text-left">
                            <dl className="sm:divide-y sm:divide-gray-200">
                                <div className="py-3 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">Full Name</dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                        <input type="text" name='name' value={data?.profile?.name || ''} onChange={handleInputChange} className='border h-10 pl-2' />
                                    </dd>
                                </div>
                                <div className="py-3 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">Email Address</dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex items-center">
                                        {data?.profile?.email}
                                    </dd>
                                </div>
                                <div className="py-3 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">Phone Number</dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex items-center">
                                        <input type="text" name='phoneNumber' value={data?.profile?.phoneNumber || ''} onChange={handleInputChange} className='border h-10 pl-2' />
                                    </dd>
                                </div>
                                <div className="py-3 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">Address</dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                        <textarea name='address' onChange={handleInputChange} className='border pl-2' rows="5" cols="40">{data?.profile?.address || ''}</textarea>
                                    </dd>
                                </div>
                            </dl>
                            <div className='md:ml-5 border-t'>
                                <Button sx={{marginTop:'10px'}} variant="contained" onClick={handleUpdateProfile}>Update</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default EditProfile;
