import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import BASE_URL from '../../../config/config'
import { ToastContainer, toast } from 'react-toastify'
import SpinnerAntd from '../../../components/spinner/SpinnerAntd'
import loginImage from '../../../assets/images/login.jpg'
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const SignIn = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    
    useEffect(() => {
        const login = localStorage.getItem('login')
        if (login === 'true') {
            window.location.href = '/'
        }
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            setLoading(true)
            const response = await axios.post(`${BASE_URL}/login`, { email, password })
            toast('Login successful', 'success')
            localStorage.setItem('login', true)
            localStorage.setItem('id', response.data.user.data.user_id)
            
            window.location.reload()
            setTimeout(() => {
                setLoading(false)
                window.history.back()
            }, 3000)
        } catch (error) {
            setError(error.response.data.message)
            toast('Invalid credentials', 'error')
            setLoading(false)
        }
    }

    return (
        <>
            {loading && <SpinnerAntd />}
            <ToastContainer />
            <div className="flex w-full max-w-sm mx-auto overflow-hidden bg-white rounded-lg shadow-lg dark:bg-gray-800 lg:max-w-[1240px] my-10 md:my-24">
                <div className="hidden lg:block lg:w-1/2 border relative">
                    <img src={loginImage} alt="" width={'100%'} height={'100%'} />
                </div>
                <div className="w-full px-6 py-8 md:px-16 lg:w-1/2">
                    <div className="flex justify-center mx-auto">
                        <h2 className='font-Inter font-bold text-2xl uppercase dark:text-white'>Sign In</h2>
                    </div>
                    <form id='formData' onSubmit={handleSubmit}>
                        <p className="mt-3 text-xl text-center text-gray-600 dark:text-gray-200">Sign in to access your account</p>
                        <p className="mt-3 text-xl text-center text-red">
                            {error && <label className="block mb-2 text-sm font-medium text-red text-left">{error}</label>}
                        </p>
                        <div className="mt-4">
                            <label className="block mb-2 text-sm font-medium text-gray-600 dark:text-gray-200 text-left" htmlFor="LoggingEmailAddress">Email Address</label>
                            <input
                                id="LoggingEmailAddress"
                                className="block w-full px-4 py-2 text-gray-700 bg-white border rounded-lg dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring focus:ring-blue-300"
                                type="email"
                                name='email'
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </div>
                        <div className="mt-4">
                            <div className="flex justify-between">
                                <label className="block mb-2 text-sm font-medium text-gray-600 dark:text-gray-200" htmlFor="loggingPassword">Password</label>
                                {/* <a href="#a" className="text-xs text-gray-500 dark:text-gray-300 hover:underline">Forget Password?</a> */}
                            </div>
                            <div className="relative">
                                <input
                                    id="loggingPassword"
                                    className="block w-full px-4 py-2 text-gray-700 bg-white border rounded-lg dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring focus:ring-blue-300"
                                    type={showPassword ? 'text' : 'password'}
                                    name='password'
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                />
                                <button
                                    type="button"
                                    className="absolute right-2 top-2"
                                    onClick={() => setShowPassword(!showPassword)}
                                >
                                    {showPassword ? <VisibilityOffIcon/> : <VisibilityIcon />}
                                </button>
                            </div>
                        </div>
                        <div className="mt-6">
                            <button className="w-full px-6 py-3 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-gray-800 rounded-lg hover:bg-gray-700 focus:outline-none focus:ring focus:ring-gray-300 focus:ring-opacity-50">Sign In</button>
                        </div>
                    </form>
                    <div className="flex items-center justify-between mt-4">
                        <span className="w-1/5 border-b dark:border-gray-600 md:w-1/4"></span>
                        <Link className="text-xs text-gray-500 uppercase dark:text-gray-400 hover:underline" to={'/sign-up'}>or sign up</Link>
                        <span className="w-1/5 border-b dark:border-gray-600 md:w-1/4"></span>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SignIn
