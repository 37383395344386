import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Home from '../pages/home/Home';
import ContactUs from '../pages/contact/ContactUs';
import TopbarHeader from '../pages/layout/topbarHeader/TopbarHeader';
import FooterHeader from '../pages/layout/footerHeader/FooterHeader';
import '../App.css';
import Testimonial from '../pages/testimonials/Testimonial';
import Curriculum from '../pages/curriculum/Curriculum';
import BookStore from '../pages/bookStore/BookStore';
import SignUp from '../pages/authentications/signUp/SignUp';
import SignIn from '../pages/authentications/signIn/SignIn';
import TermAndService from '../pages/privacyPolicy/TermAndService';
import PrivacyPolicy from '../pages/privacyPolicy/PrivacyPolicy';
import LearningResources from '../pages/learningResources/video/LearningResources';
import Profile from '../components/profile/Profile';
import FAQ from '../pages/faq/FAQ';
import EditProfile from '../components/profile/EditProfile';
import CurriculumSubject from '../pages/curriculum/CurriculumSubject';
import CurriculumTopic from '../pages/curriculum/CurriculumTopic';
import Subject from '../pages/learningResources/video/Subject';
import Topic from '../pages/learningResources/video/Topic';
import Video from '../pages/learningResources/video/Video';

const PublicRoute = () => {
  return (
    <BrowserRouter>
      <div className='App flex flex-col min-h-screen  dark:bg-bgdark'>
        <TopbarHeader />
        {/* <Navbar /> */}
        <div className='Content flex-grow'>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/*" element={<Navigate to={'/'} />} />
            <Route path="/curriculum" element={<Curriculum />} />
            <Route path="/curriculum/:title" element={<CurriculumSubject />} />
            <Route path="/curriculum/:title/subject/:subjectName" element={<CurriculumTopic />} />
            <Route path="/book-store" element={<BookStore />} />
            <Route path="/learnign-resources" element={<LearningResources />} />
            <Route path="/:className/subject/:id" element={<Subject />} />
            <Route path="/:className/:classId/:subjectName/:id" element={<Topic />} />
            <Route path="/:topicName/:id" element={<Video/>} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/sign-up" element={<SignUp />} />
            <Route path="/sign-in" element={<SignIn />} />
            <Route path="/profile-setting" element={<Profile />} />
            <Route path="/edit-profile" element={<EditProfile />} />
            <Route path="/testimonials" element={<Testimonial />} />
            <Route path="/term-service" element={<TermAndService />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/faqs" element={<FAQ />} />
            
            {/* <Route path="/data" element={<ClassList classes={data} />} /> */}
          {/* <Route path="/subject/:classGrade/:subjectName" element={<SubjectList classes={data} />} /> */}
          </Routes>
        </div>
        <FooterHeader />
      </div>
    </BrowserRouter>
  );
}

export default PublicRoute;
