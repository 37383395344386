import React, { useEffect, useState } from 'react';
import SpinnerAntd from '../../../components/spinner/SpinnerAntd';
import SidebarGrade from '../SidebarGrade';
import { RightOutlined } from '@ant-design/icons';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Link, useParams } from 'react-router-dom';
import BASE_URL from '../../../config/config';
import axios from 'axios';
import HeroSection from '../../layout/heroSection/HeroSection';

const Topic = () => {
  const { id, classId, className, subjectName } = useParams();
  
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [islogin, setIsLogin] = useState(false);

  useEffect(() => {
    if (localStorage.getItem('login')) {
      setIsLogin(true);
    }
  }, []);

  const handleClick = () => {
    window.location.href = '/sign-in';
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/topic`);
        setData(response.data);
        setLoading(false);
      } catch (err) {
        console.error('Failed to fetch topics:', err);
      }
    };

    fetchData();
  }, []);

  const filteredTopics = data.filter(topic => 
    topic.class_id === parseInt(classId, 10) && topic.subject_id === parseInt(id, 10)
  );

  return (
    <>
      <HeroSection img="https://royal-education-image.vercel.app/learningResources.png" title={`${className}/${subjectName}`} description="Fueling Curiosity, Enhancing Knowledge." />
      {
        islogin ? (
          <div className="container mx-auto max-w-[1240px] py-16 md:py-28">
            <div data-aos="fade-up" data-aos-delay="200">
              <h2 className="font-semibold text-3xl text-black font-Inter uppercase dark:text-white">{subjectName}</h2>
            </div>
            <div className="flex">
              <SidebarGrade />
              <div className="px-4 flex-grow">
                {loading ? (
                  <SpinnerAntd />
                ) : (
                  <>
                    <ul className="grid grid-cols-1 gap-8 mt-8 xl:mt-12 xl:gap-12 md:grid-cols-2 text-left">
                      {filteredTopics.length > 0 ? (
                        filteredTopics.map((topic, index) => (
                          <li key={index} className="p-6 border rounded-xl border-r-gray-200 dark:border-gray-700">
                            <div className="md:flex md:items-start md:-mx-4">
                              <span className="inline-block p-2 text-white bg-blue rounded-xl md:mx-4">
                                <CheckCircleIcon />
                              </span>
                              <div className="mt-4 md:mx-4 md:mt-0">
                                <h1 className="text-xl font-medium text-gray-700 capitalize dark:text-white">
                                  {topic.name} 
                                </h1>
                                <p className="mt-3 text-gray-500 dark:text-gray-300">
                                  {topic.description}
                                </p>
                                <Link to={`/${topic.name}/${topic.id}`} className="text-green-500 dark:text-darkwhite font-Inter mt-3 block">
                                  View More <RightOutlined />
                                </Link>
                              </div>
                            </div>
                          </li>
                        ))
                      ) : (
                        <p className="text-gray-500 dark:text-gray-300 text-center">(Topic not found)</p>
                      )}
                    </ul>
                  </>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="container mx-auto max-w-[1240px] py-16 md:py-28">
            <h2 className="font-semibold text-3xl text-black font-Inter dark:text-white">Please login to access learning resources</h2>
            <p className='py-1 mt-5 px-3 rounded-md inline-block bg-blue cursor-pointer text-white hover:bg-white hover:text-blue hover:border' onClick={handleClick}>Go to Login?</p>
          </div>
        )
      }
    </>
  );
}

export default Topic;
